import React from "react";
import CommunityDetails from "../community-details";
import styles from "./community-sidebar.module.scss";
import { CrossIcon } from "../icons";
import { SIDEBAR_WIDTH, SIDEBAR_RIGHT_PERCENT } from "../../style/variables";

export const CommunitySidebar = ({ onClose }) => {
  return (
    <div
      className={styles.communitySidebar}
      style={{ width: SIDEBAR_WIDTH, right: `${SIDEBAR_RIGHT_PERCENT}%` }}
    >
      <div className={styles.communitySidebarInner}>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label={"Close sidebar"}
        >
          <CrossIcon height={12} width={12} />
        </button>
        <div className={styles.communityDetailsWrapper}>
          <CommunityDetails />
        </div>
      </div>
    </div>
  );
};

export default CommunitySidebar;
