export const AUSTRALIA_VIEWPORT = {
  latitude: -27.8,
  longitude: 133.454,
  zoom: 3.25,
};

export const AUSTRALIA_MOBILE_VIEWPORT = {
  latitude: -29.3,
  longitude: 133.4,
  zoom: 2.4,
};

// Different layout to community version
export const AUSTRALIA_PUBLIC_MOBILE_VIEWPORT = {
  latitude: -28,
  longitude: 133.4,
  zoom: 2.3,
};

export const NT_VIEWPORT = {
  latitude: -18.67,
  longitude: 133.77,
  zoom: 4.61,
};

export const QLD_VIEWPORT = {
  latitude: -18.745,
  longitude: 142.41,
  zoom: 4.22,
};

export const WA_VIEWPORT = {
  latitude: -24.88,
  longitude: 122.61,
  zoom: 4.01,
};

export const SA_VIEWPORT = {
  latitude: -32.463,
  longitude: 135.91,
  zoom: 4.627,
};

export const VIC_VIEWPORT = {
  latitude: -37.126,
  longitude: 144.416,
  zoom: 5.67,
};

export const NSW_VIEWPORT = {
  latitude: -33.68,
  longitude: 145.445,
  zoom: 4.996,
};

export const TAS_VIEWPORT = {
  latitude: -41.8,
  longitude: 146.636,
  zoom: 5.76,
};

export const zoomOptions = [
  { label: "Australia", value: AUSTRALIA_VIEWPORT },
  { label: "Northern Territory", value: NT_VIEWPORT },
  { label: "Western Australia", value: WA_VIEWPORT },
  { label: "Queensland", value: QLD_VIEWPORT },
  { label: "South Australia", value: SA_VIEWPORT },
  { label: "New South Wales", value: NSW_VIEWPORT },
  { label: "Victoria", value: VIC_VIEWPORT },
  { label: "Tasmania", value: TAS_VIEWPORT },
];
