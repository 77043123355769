import React from "react";
import styles from "./community-search.module.scss";
import { connect } from "react-redux";
import { selectCommunityInputValue } from "../../ducks";
import Highlighter from "react-highlight-words";

// Custom option component, displays alternative names
const Option = ({ label, value, data, innerProps, communityInputValue }) => {
  const { alternativeNames, billingState, region } = data;

  const regionInSearch =
    region &&
    communityInputValue &&
    region.toLowerCase().includes(communityInputValue.toLowerCase());

  return (
    <div className={styles.searchOption} {...innerProps} key={value}>
      <div style={{ marginBottom: 3 }}>
        <span>
          <Highlighter
            searchWords={[communityInputValue]}
            textToHighlight={label}
            highlightClassName={styles.highlight}
          />
        </span>
        {billingState && (
          <span>
            <span>, </span>
            <Highlighter
              searchWords={[communityInputValue]}
              textToHighlight={billingState}
              highlightClassName={styles.highlight}
            />
          </span>
        )}
      </div>
      {alternativeNames && (
        <div className={styles.alsoKnownAs}>
          <span>Also known as </span>
          <span className={styles.alternativeNames}>
            <Highlighter
              searchWords={[communityInputValue]}
              textToHighlight={alternativeNames}
              highlightClassName={styles.highlight}
            />
          </span>
        </div>
      )}
      {regionInSearch && (
        <div className={styles.alsoKnownAs}>
          <span>Region: </span>
          <span className={styles.alternativeNames}>
            <Highlighter
              searchWords={[communityInputValue]}
              textToHighlight={region}
              highlightClassName={styles.highlight}
            />
          </span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    communityInputValue: selectCommunityInputValue(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Option);
