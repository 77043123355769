import React from "react";
import styles from "./community-search.module.scss";
import { connect } from "react-redux";
import {
  selectCommunitiesForSearch,
  selectCommunityValue,
  selectCommunityInputValue,
  setActiveCommunityById as _setActiveCommunityById,
  setCommunityValue as _setCommunityValue,
  setCommunityInputValue as _setCommunityInputValue,
} from "../../ducks";
import SearchBar from "../search-bar";
import Option from "./CommunitySearchOption";

// Search should match both community name and alternative names
const communityFilter = (option, searchText) => {
  const { alternativeNames, region, billingState } = option.data;
  const needle = searchText.toLowerCase();
  const haystack = `${option.label} ${alternativeNames} ${region} ${billingState}`.toLowerCase();

  return haystack.includes(needle);
};

const CommunitySearch = ({
  communities,
  setActiveCommunityById,
  setCommunityValue,
  communityValue,
  setCommunityInputValue,
  communityInputValue,
}) => {
  return (
    <div className={styles.communitySearchWrapper}>
      <SearchBar
        options={communities}
        noOptionsMessage={() => (
          <span>
            No community matching <b>{communityInputValue}</b>
          </span>
        )}
        placeholder={"Search by community name"}
        optionComponent={{ Option }}
        value={communityValue}
        onChange={(opt, x) => {
          const newActiveCommunity = opt ? opt.value : null;
          setCommunityValue(opt);
          setActiveCommunityById(newActiveCommunity, "searchbar");
        }}
        onMenuOpen={() => {
          setCommunityValue(null);
        }}
        filterOption={communityFilter}
        inputValue={communityInputValue}
        onInputChange={(x, { action }) => {
          if (action === "set-value" || action === "input-change") {
            setCommunityInputValue(x);
          }
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    communities: selectCommunitiesForSearch(state),
    communityValue: selectCommunityValue(state),
    communityInputValue: selectCommunityInputValue(state),
  };
};

const mapDispatchToProps = {
  setActiveCommunityById: _setActiveCommunityById,
  setCommunityValue: _setCommunityValue,
  setCommunityInputValue: _setCommunityInputValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySearch);
