import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./languages-list.module.scss";
import {
  selectActiveLanguage,
  selectActiveCommunity,
  selectIsMobile,
  setActiveLanguageById as _setActiveLanguageById,
  setLanguageValue as _setLanguageValue,
  setActiveCommunity as _setActiveCommunity,
  setCommunityValue as _setCommunityValue,
} from "../../ducks";
import LanguageCard from "../language-card";
import AppButton from "../app-button";
import { NoResourcesMessage } from "../messages";

const LanguagesList = ({
  displayLanguages,
  activeLanguage,
  setActiveLanguageById,
  setLanguageValue,
  isMobile,
  activeCommunity,
  setActiveCommunity,
  setCommunityValue,
}) => {
  const { languages, relatedLanguages } = displayLanguages;

  const [expanded, setExpanded] = useState(false);
  const hasLanguages = languages.length > 0;
  const hasRelatedLanguages = relatedLanguages.length > 0;
  const activeCommunityOnMobile = isMobile && activeCommunity;

  const languagesToShow = expanded ? [...languages] : languages.slice(0, 12);

  return (
    <div className={styles.languagesList}>
      {!hasLanguages && <NoResourcesMessage activeLanguage={activeLanguage} />}

      {hasLanguages && (
        <div className={styles.languageGrid}>
          {languagesToShow.map((l) => {
            return <LanguageCard language={l} key={l.languageId} />;
          })}
        </div>
      )}

      {activeCommunityOnMobile && (
        <AppButton
          onClick={() => {
            setActiveCommunity("");
            setCommunityValue(null);
            setActiveLanguageById(null);
            setLanguageValue(null);
          }}
          label="Clear community selection"
          color="blue"
          filled={false}
          crossIcon={true}
        />
      )}

      {hasRelatedLanguages && (
        <div className={styles.relatedLanguages}>
          <div className={styles.relatedLanguagesHeader}>
            <span>Other languages you may be interested in</span>
          </div>
          <div className={styles.languageGrid}>
            {relatedLanguages.map((l) => {
              return <LanguageCard language={l} key={l.languageId} />;
            })}
          </div>
        </div>
      )}
      {activeLanguage && !activeCommunityOnMobile && (
        <AppButton
          onClick={() => {
            setActiveLanguageById(null);
            setLanguageValue(null);
          }}
          label="Clear language selection"
          color="blue"
          filled={false}
          crossIcon={true}
        />
      )}
      {!expanded && !activeLanguage && !activeCommunityOnMobile && (
        <AppButton
          onClick={() => {
            setExpanded(true);
          }}
          label={`Show all ${languages.length} languages with books`}
          color="blue"
          filled={true}
          crossIcon={false}
        />
      )}
      {expanded && !activeLanguage && (
        <AppButton
          onClick={() => {
            setExpanded(false);
          }}
          label={`Hide additional languages`}
          color="blue"
          filled={true}
          crossIcon={false}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeLanguage: selectActiveLanguage(state),
    activeCommunity: selectActiveCommunity(state),
    isMobile: selectIsMobile(state),
  };
};

const mapDispatchToProps = {
  setActiveLanguageById: _setActiveLanguageById,
  setLanguageValue: _setLanguageValue,
  setActiveCommunity: _setActiveCommunity,
  setCommunityValue: _setCommunityValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesList);
