export const strings = {
  urls: {
    contact:
      "https://www.indigenousliteracyfoundation.org.au/contact-programs-team",
    bookSupply: "https://www.indigenousliteracyfoundation.org.au/book-supply",
    bookBuzz: "https://www.indigenousliteracyfoundation.org.au/book-buzz",
  },
  messages: {
    mobileContact: "Can't find your community? Suggestions? ",
    desktopContact:
      "If you can't find your community, or something looks incorrect, ",
  },
};
