import React from "react";
import styles from "./language-search.module.scss";
import { connect } from "react-redux";
import {
  selectLanguagesForSearch,
  selectLanguageValue,
  setActiveLanguageById as _setActiveLanguageById,
  setLanguageValue as _setLanguageValue,
  setLanguageInputValue as _setLanguageInputValue,
  selectLanguageInputValue,
} from "../../ducks";
import SearchBar from "../search-bar";
import Option from "./LanguageSearchOption";

// Search should match both community name and alternative names
const filterFunction = (option, searchText) => {
  const { alternativeNames } = option.data;
  return (
    option.label.toLowerCase().includes(searchText.toLowerCase()) ||
    alternativeNames.toLowerCase().includes(searchText.toLowerCase())
  );
};

const LanguageSearch = ({
  languages,
  setActiveLanguageById,
  setLanguageValue,
  languageValue,
  setLanguageInputValue,
  languageInputValue,
}) => {
  return (
    <div className={styles.languageSearchWrapper}>
      <SearchBar
        options={languages}
        placeholder={"Search for a language"}
        noOptionsMessage={() => (
          <span>
            No language matching <b>{languageInputValue}</b>
          </span>
        )}
        optionComponent={{ Option }}
        value={languageValue}
        setValue={setLanguageValue}
        onMenuOpen={() => {
          setLanguageValue(null);
        }}
        onChange={(opt) => {
          const newActiveLanguageId = opt ? opt.value : null;
          setLanguageValue(opt);
          setActiveLanguageById(newActiveLanguageId);
        }}
        filterOption={filterFunction}
        inputValue={languageInputValue}
        onInputChange={(x, { action }) => {
          if (action === "set-value" || action === "input-change") {
            setLanguageInputValue(x);
          }
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languages: selectLanguagesForSearch(state),
    languageValue: selectLanguageValue(state),
    languageInputValue: selectLanguageInputValue(state),
  };
};

const mapDispatchToProps = {
  setActiveLanguageById: _setActiveLanguageById,
  setLanguageValue: _setLanguageValue,
  setLanguageInputValue: _setLanguageInputValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSearch);
