import React from "react";
import styles from "./search-area.module.scss";
import Filters from "../filters";
import CommunitySearch from "../community-search";

const SearchArea = ({ isCommunityVersion }) => {
  return (
    <div
      className={`${styles.searchArea} ${
        isCommunityVersion ? styles.communityVersion : styles.publicVersion
      }`}
    >
      <div className={styles.searchAreaInner}>
        <h1 style={{ color: "white" }}>
          {isCommunityVersion ? "Find a community" : "Where we work"}
        </h1>
        <div style={{ marginBottom: 20 }}>
          <CommunitySearch />
        </div>
        <div className={styles.filtersArea}>
          <p
            style={{
              textAlign: isCommunityVersion ? "left" : "center",
              color: "white",
            }}
          >
            Only show communities with
          </p>
          <Filters />
        </div>
      </div>
    </div>
  );
};

export default SearchArea;
