import { getMapData } from "../api";

const initialState = {
  data: {
    communities: [],
    languages: [],
    books: [],
    regions: [],
    bookBuzzOrgs: [],
    bookSupplyOrgs: [],
  },
  ui: {
    filters: [],
    activeCommunity: "",
    activeLanguage: null,
    hoveredCommunity: "",
    languageValue: null,
    communityValue: null,
    communityInputValue: "",
    languageInputValue: "",
    isMobile: false,
    isCommunityVersion: false,
  },
};

const getDataById = (key, idField, id, state) => {
  const field = state.data[key];
  const ret = field.find((f) => f[idField] === id);
  return ret;
};

const actionTypes = {
  DATA_SUCCESS: "DATA_SUCCESS",
  DATA_REQUEST: "DATA_REQUEST",
  SET_PROJECT_DATA: "PROJECT_DATA:SET",
  DATA_ERROR: "DATA_ERROR",
  SET_ACTIVE_FILTERS: "SET_ACTIVE_FILTERS",
  SET_FILTERS: "SET_FILTERS",
  TOGGLE_FILTER: "TOGGLE_FILTER",
  SET_ACTIVE_COMMUNITY: "SET_ACTIVE_COMMUNITY",
  SET_ACTIVE_LANGUAGE: "SET_ACTIVE_LANGUAGE",
  SET_ACTIVE_COMMUNITY_BY_ID: "SET_ACTIVE_COMMUNITY_BY_ID",
  SET_ACTIVE_LANGUAGE_BY_ID: "SET_ACTIVE_LANGUAGE_BY_ID",
  SET_LANGUAGE_VALUE: "SET_LANGUAGE_VALUE",
  SET_COMMUNITY_VALUE: "SET_COMMUNITY_VALUE",
  SET_COMMUNITY_INPUT_VALUE: "SET_COMMUNITY_INPUT_VALUE",
  SET_LANGUAGE_INPUT_VALUE: "SET_LANGUAGE_INPUT_VALUE",
  SET_HOVERED_COMMUNITY: "SET_HOVERED_COMMUNITY",
  SET_IS_MOBILE: "SET_IS_MOBILE",
  SET_IS_COMMUNITY_VERSION: "SET_IS_COMMUNITY_VERSION",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_COMMUNITY_VERSION:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCommunityVersion: action.payload,
        },
      };
    case actionTypes.SET_FILTERS:
      return {
        ...state,
        ui: {
          ...state.ui,
          filters: action.payload,
        },
      };
    case actionTypes.SET_COMMUNITY_INPUT_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          communityInputValue: action.payload,
        },
      };
    case actionTypes.SET_LANGUAGE_INPUT_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          languageInputValue: action.payload,
        },
      };
    case actionTypes.SET_LANGUAGE_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          languageValue: action.payload,
        },
      };
    case actionTypes.SET_COMMUNITY_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          communityValue: action.payload,
        },
      };
    case actionTypes.SET_IS_MOBILE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isMobile: action.payload,
        },
      };
    case actionTypes.SET_HOVERED_COMMUNITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          hoveredCommunity: action.payload,
        },
      };
    case actionTypes.DATA_SUCCESS:
      const data = action.payload;
      return {
        ...state,
        data: data,
      };
    case actionTypes.DATA_ERROR:
      console.log("Error: ", action.payload);
      return state;
    case actionTypes.SET_ACTIVE_COMMUNITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeCommunity: action.payload,
        },
      };
    case actionTypes.SET_ACTIVE_COMMUNITY_BY_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeCommunity: action.payload.id,
        },
      };
    case actionTypes.SET_ACTIVE_LANGUAGE_BY_ID:
      const langId = action.payload;

      // Return both active language and its related languages in full
      const activeLanguage = langId
        ? state.data.languages.find((l) => l.languageId === langId)
        : null;
      const relatedLanguages = activeLanguage
        ? activeLanguage.languages.map((relatedLangId) => {
            return state.data.languages.find(
              (l) => l.languageId === relatedLangId
            );
          })
        : null;
      const newActiveLanguage = activeLanguage
        ? { ...activeLanguage, languages: relatedLanguages }
        : null;

      return {
        ...state,
        ui: {
          ...state.ui,
          activeLanguage: newActiveLanguage,
        },
      };
    case actionTypes.TOGGLE_FILTER:
      const filter = action.payload;
      const filters = state.ui.filters;
      const allSelected = !filters.some((f) => !f.active);

      if (allSelected) {
        // If everything was selected, just select the clicked button
        return {
          ...state,
          ui: {
            ...state.ui,
            filters: filters.map((f) => {
              return { ...f, active: f.key === filter.key };
            }),
          },
        };
        // Otherwise, toggle the clicked button
      } else {
        return {
          ...state,
          ui: {
            ...state.ui,
            filters: filters.map((f) => {
              return {
                ...f,
                active: f.key === filter.key ? !f.active : f.active,
              };
            }),
          },
        };
      }

    default:
      return state;
  }
};

// Actions
export const setActiveFilters = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_FILTERS,
    payload: payload,
  };
};

export const setFilters = (payload) => {
  return {
    type: actionTypes.SET_FILTERS,
    payload: payload,
  };
};

export const setIsCommunityVersion = (payload) => {
  return {
    type: actionTypes.SET_IS_COMMUNITY_VERSION,
    payload: payload,
  };
};

export const setIsMobile = (payload) => {
  return {
    type: actionTypes.SET_IS_MOBILE,
    payload: payload,
  };
};

export const setLanguageValue = (payload) => {
  return {
    type: actionTypes.SET_LANGUAGE_VALUE,
    payload: payload,
  };
};

export const setActiveCommunity = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_COMMUNITY,
    payload: payload,
  };
};

export const setCommunityValue = (payload) => {
  return {
    type: actionTypes.SET_COMMUNITY_VALUE,
    payload: payload,
  };
};

export const setCommunityInputValue = (payload) => {
  return {
    type: actionTypes.SET_COMMUNITY_INPUT_VALUE,
    payload: payload,
  };
};

export const setLanguageInputValue = (payload) => {
  return {
    type: actionTypes.SET_LANGUAGE_INPUT_VALUE,
    payload: payload,
  };
};

// Id of the community, where it has been set from
export const setActiveCommunityById = (id, setter) => {
  return {
    type: actionTypes.SET_ACTIVE_COMMUNITY_BY_ID,
    payload: { id: id, setter: setter },
  };
};

export const setActiveLanguageById = (id) => {
  return {
    type: actionTypes.SET_ACTIVE_LANGUAGE_BY_ID,
    payload: id,
  };
};

export const setHoveredCommunity = (id) => {
  return {
    type: actionTypes.SET_HOVERED_COMMUNITY,
    payload: id,
  };
};

export const toggleFilter = (payload) => {
  return {
    type: actionTypes.TOGGLE_FILTER,
    payload: payload,
  };
};

// Simple selectors

export const selectActiveLanguage = (state) => {
  return state.ui.activeLanguage;
};

export const selectIsCommunityVersion = (state) => {
  return state.ui.isCommunityVersion;
};

export const selectLanguageValue = (state) => {
  return state.ui.languageValue;
};

export const selectIsMobile = (state) => {
  return state.ui.isMobile;
};

export const selectCommunityValue = (state) => {
  return state.ui.communityValue;
};

export const selectCommunityInputValue = (state) => {
  return state.ui.communityInputValue;
};

export const selectLanguageInputValue = (state) => {
  return state.ui.languageInputValue;
};

export const selectHoveredCommunity = (state) => {
  return state.ui.hoveredCommunity;
};

export const selectFilters = (state) => {
  return state.ui.filters;
};

// Complex selectors

export const selectActiveCommunity = (state) => {
  const { languages, books, regions, bookBuzzOrgs, bookSupplyOrgs, events } =
    state.data;
  const id = state.ui.activeCommunity;
  const activeCommunity = state.data.communities.find(
    (c) => c.accountId === id
  );

  if (!activeCommunity) return null;

  const bBuzz = activeCommunity.bookBuzzServiceOrgs.map((org) => {
    return bookBuzzOrgs.find((o) => o.serviceOrganisationsAccountId === org);
  });
  const reg = activeCommunity.regions.map((reg) => {
    return regions.find((r) => r.regionId === reg);
  });
  const lang = activeCommunity.languages.map((language) => {
    return languages.find((r) => r.languageId === language);
  });
  const bSupply = activeCommunity.bookSupplyOrgs.map((org) => {
    return bookSupplyOrgs.find(
      (o) => o.opportunityAccountNameAccountId === org
    );
  });
  const bks = activeCommunity.books.map((book) => {
    const ret = books.find((b) => b.hiddenRecordId === book);
    return ret;
  });
  const evnts = activeCommunity.events.map((event) => {
    return events.find((e) => e.communityEventCommunityEventId === event);
  });

  return {
    ...activeCommunity,
    bookBuzzServiceOrgs: bBuzz,
    regions: reg,
    languages: lang,
    books: bks,
    bookSupplyOrgs: bSupply,
    events: evnts,
  };
};

export const selectActiveCommunities = (state) => {
  const filters = state.ui.filters;
  const communities = state.data.communities;
  const noFiltersSelected = !filters.some((f) => f.active);
  const activeFilters = filters.filter((f) => f.active);

  // If there's no filters on, return everything
  if (noFiltersSelected) {
    return communities;
  } else {
    // Otherwise return communities that match the filters
    return communities.filter((c) => {
      return activeFilters.some((f) => c[f.key] && c[f.key].length);
    });
  }
};

export const selectCommunitiesForSearch = (state) => {
  const { communities, regions } = state.data;

  if (!communities) return [];

  return communities.map((c) => {
    // Regions for 'hidden search'
    const communityRegions = c.regions.map((reg) => {
      const match = regions.find((r) => r.regionId === reg);
      return match.regionRegionName;
    });

    return {
      value: c.accountId,
      label: c.accountName,
      alternativeNames: c.alternativeNames,
      billingState: c.billingState,
      region: communityRegions.join(", "),
    };
  });
};

export const selectLanguagesForSearch = (state) => {
  if (!state.data.languages) return [];

  return state.data.languages.map((l) => {
    return {
      value: l.languageId,
      label: l.languageLanguageName,
      alternativeNames: l.alternativeNames,
    };
  });
};

// Languages that appear below the language search bar
// This is quite complex, as it depends on the active language, mobile view,
// whether there is data for the community, whether there's related languages, etc.
export const selectLanguagesForCards = (state) => {
  const { activeLanguage, activeCommunity, isMobile } = state.ui;
  const { languages, communities } = state.data;

  const activeCommunityDetails = communities.find(
    (c) => c.accountId === activeCommunity
  );
  const activeCommunityLanguages = activeCommunityDetails
    ? activeCommunityDetails.languages.map((language) => {
        return languages.find((r) => r.languageId === language);
      })
    : [];

  const activeCommunityOnMobile = isMobile && activeCommunity;

  let returnLangs = {
    languages: [],
    relatedLanguages: [],
    activeCommunityHasLanguages: false,
  };

  if (!languages) {
    return returnLangs;
  }
  // If there's an active community on mobile and it has language data,
  // We only show languages related to the community
  else if (activeCommunityOnMobile && activeCommunityLanguages.length) {
    returnLangs = {
      languages: activeCommunityLanguages,
      relatedLanguages: [],
      activeCommunityHasLanguages: true,
    };
  }
  // We only want to display languages that have books
  // OR languages with related languages that have books
  else if (activeLanguage) {
    const languageHasBooks = activeLanguage.books.length;
    const relatedLanguagesWithBooks = activeLanguage.languages.filter(
      (l) => l.books.length
    );
    const relatedLanguageHasBooks = relatedLanguagesWithBooks.length > 0;

    returnLangs = {
      languages: languageHasBooks ? [activeLanguage] : [],
      relatedLanguages: relatedLanguageHasBooks
        ? [...relatedLanguagesWithBooks]
        : [],
      activeCommunityHasLanguages: false,
    };
  }
  // Default list of all languages with books
  else {
    const mainLanguages = languages
      .filter((l) => l.books.length)
      .sort((a, b) => {
        if (a.books.length === b.books.length) {
          return a.languageLanguageName > b.languageLanguageName ? 1 : -1;
        } else {
          return a.books.length > b.books.length ? -1 : 1;
        }
      });

    returnLangs = {
      languages: mainLanguages,
      relatedLanguages: [],
      activeCommunityHasLanguages: false,
    };
  }

  const ret = {
    ...returnLangs,
    languages: returnLangs.languages.map((l) => {
      return {
        ...l,
        books: l.books.map((b) =>
          getDataById("books", "hiddenRecordId", b, state)
        ),
      };
    }),
    relatedLanguages: returnLangs.relatedLanguages.map((l) => {
      return {
        ...l,
        books: l.books.map((b) =>
          getDataById("books", "hiddenRecordId", b, state)
        ),
      };
    }),
  };
  return ret;
};

// Thunks
export const fetchData = (isCommunityVersion) => {
  return (dispatch) => {
    return getMapData()
      .then((response) => {
        // If we're showing the public version,
        // Only show projects that have 'public data', i.e. some kind of project
        const data = isCommunityVersion
          ? response
          : {
              ...response,
              communities: response.communities.filter((c) => c.hasPublicData),
            };
        dispatch({ type: "DATA_SUCCESS", payload: data });
      })
      .catch((err) => {
        dispatch({ type: "DATA_ERROR", payload: err });
      });
  };
};

export default reducer;
