import React from "react";
import { connect } from "react-redux";
import styles from "./languages-area.module.scss";
import LanguageSearch from "../language-search";
import LanguagesList from "../languages-list";
import {
  selectActiveCommunity,
  selectIsMobile,
  selectLanguagesForCards,
  selectIsCommunityVersion,
} from "../../ducks";
import { NoLanguageDataMessage } from "../messages";

const LanguagesArea = ({
  activeCommunity,
  isMobile,
  displayLanguages,
  isCommunityVersion,
}) => {
  const activeCommunityOnMobile = isMobile && activeCommunity;
  const hasLanguages = activeCommunity && activeCommunity.books.length;
  const hideSearch = activeCommunityOnMobile && hasLanguages;
  const { activeCommunityHasLanguages } = displayLanguages;

  const noLanguageData =
    activeCommunityOnMobile && !activeCommunityHasLanguages;

  return (
    <div className={styles.languagesArea}>
      {noLanguageData && isCommunityVersion && (
        <div>
          <NoLanguageDataMessage activeCommunity={activeCommunity} />
        </div>
      )}
      <div className={styles.languageSearchArea}>
        {isCommunityVersion && (
          <div className={styles.languageSearchInner}>
            <h2
              id="language-resources"
              style={{ color: "white", paddingBottom: hideSearch ? 0 : 24 }}
            >
              {hideSearch ? (
                <span>{`Languages in ${activeCommunity.accountName}`}</span>
              ) : (
                <span>Books in languages</span>
              )}
            </h2>
            {!hideSearch && <LanguageSearch />}
          </div>
        )}
        {!isCommunityVersion && (
          <p className={styles.publicCta} style={{ color: "white" }}>
            <span>
              If you are living or working in a remote community and are
              interested in our programs or books, please{" "}
            </span>
            <a href="https://www.indigenousliteracyfoundation.org.au/get-in-touch">
              get in touch
            </a>
            .
          </p>
        )}
      </div>
      {isCommunityVersion && (
        <div className={styles.languageListArea}>
          <img
            src={`${process.env.REACT_APP_ASSET_PATH}images/curve-blue-row-bottom.png`}
            alt=""
            className={`${styles.curveBlueLanguageSearchBottom}`}
          />
          <LanguagesList displayLanguages={displayLanguages} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    displayLanguages: selectLanguagesForCards(state),
    activeCommunity: selectActiveCommunity(state),
    isMobile: selectIsMobile(state),
    isCommunityVersion: selectIsCommunityVersion(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesArea);
