import React from "react";
import { strings } from "../../strings";
import styles from "./map.module.scss";
const { urls, messages } = strings;

export const GetInTouchMessage = ({ isMobile }) => {
  return (
    <div className={`${styles.mapMsg} ${styles.contactMsg}`}>
      <span>{isMobile ? messages.mobileContact : messages.desktopContact}</span>
      <a href={urls.contact}>{isMobile ? "Get in touch" : "get in touch"}</a>
      <span>!</span>
    </div>
  );
};

export const NumCommunitiesMessage = ({ numShowingCommunities }) => {
  return (
    <div className={`${styles.mapMsg} ${styles.numCommunitiesMsg}`}>
      <span>Showing </span>
      <span style={{ fontWeight: 900 }}>{numShowingCommunities}</span>
      <span>{numShowingCommunities === 1 ? " community" : " communities"}</span>
    </div>
  );
};

export const CopyrightMessage = () => {
  const year = new Date().getFullYear();
  return (
    <div className={`${styles.copyrightMsg}`}>
      <span>Copyright © {year} Indigenous Literacy Foundation</span>
    </div>
  );
};
