export const importance = {
  Birritjimi: 9,
  Gunyangara: 9,
  Dhuruputjpi: 7.7,
  Urapunga: 8.3,
  Bulla: 10.2,
  Amanbidji: 6,
  Kalano: 9.7,
  Beswick: 7.3,
  Pirlangimpi: 8.1,
  Pickertaramoor: 8.3,
  Belyuen: 7.3,
  Alyangula: 7.2,
  Mataranka: 7.8,
  Yirrkala: 7.6,
  Gutjangan: 7,
  Kubin: 9.3,
  Waiben: 9.6,
  Moa: 7.1,
  Horn: 7.7,
  Bamaga: 10,
  Umagica: 8.5,
  Ayton: 9.2,
  Lakeland: 6.2,
  Iama: 6.3,
  Yuelamu: 7,
  Amoonguna: 6.5,
  "Joy Springs": 13.7,
  Gillarong: 8.8,
  Muludja: 7.9,
  Looma: 7.4,
  Djugerari: 6.5,
  "Kundat Djaru": 10.3,
  Daguragu: 8.4,
  Goolminyini: 6.9,
  Papulankutja: 6.5,
  Kanpi: 7,
  Bondini: 10.5,
  Mungullah: 9.8,
  Wickham: 7.8,
  Roebourne: 7.1,
  Wirrmanu: 6.3,
  Bayulu: 6.8,
  Koorabye: 8.7,
  Djarindjin: 13.7,
  Lombadina: 6.7,
  Mowanjum: 7,
  Weipa: 8,
  Umagico: 7.8,
  Binjari: 6.5,
  Milingimbi: 6.1,
  Nganmarriaynga: 7.1,
  Minyerri: 6.5,
  Peppimenarti: 6.3,
  Yulara: 6.7,
  "Mount Liebig": 6.5,
  Gangan: 6,
  Barunga: 6.4,
  Cloncurry: 5.8,
  Yakanarra: 6.3,
  Noonkanbah: 7,
  "Guda Guda": 6.8,
  Mirima: 8.3,
  Dhalinybuy: 6.7,
  Kadjina: 6.3,
  // Papulankutja: 6.7,
  Mimbi: 5.8,
  Wogyala: 8,
  Balgo: 13,
  Quambone: 6.5,
  Rurrangala: 8.4,
  Malkala: 7.8,
  Rockhole: 8.5,
  Manyallaluk: 9,
  "Tipperary Station": 7.2,
  Nauiyu: 7.8,
  "Pandanus Park": 6.2,
  Dodnun: 7.2,
  Karmulinunga: 11.4,
  Junjuwa: 8.7,
  "Mindi Rardi": 10.4,
  Darlgunya: 10.3,
  Bungardi: 11.4,
  Ngalingkadji: 6.8,
  Saibai: 7,
  Wug: 6.5,
  Anthepe: 10.1,
  Akngwertnarre: 10.9,
  "Anthelk-Ewlpaye": 9.6,
  Karnte: 9.4,
  "Larapinta Valley": 8.9,
  Ilparpa: 8.2,
  Inarlenge: 7.9,
  "Ilperle-Tyathe": 7.7,
  "Pmara Jutunta": 6.4,
  Malgawo: 6.4,
  Baradine: 6.4,
  Collarenebri: 5.6,
  Murputja: 9.4,
  Tirralintji: 6.3,
  "Lamboo Gunian": 6.1,
  "South Hedland": 6.9,
  Yuleba: 8,
  Irrirlerre: 7.1,

  // Currently now showing
  Dhamiyaka: 12.7,
  "Yudu Yudu": 13,
  Galupa: 10.4,
  "Bukudal - Balaybalay": 8.9,
  Dhupwamirri: 8.2,
  Barraratjpi: 8.1,
  "Gulungurr - Dhoyndji": 7,
  Manabadurma: 10.8,
  "Djurrbiyuk (Kakadu)": 12,
  Kapalga: 8.5,
  "East Alligator": 8.2,
  Baygurrutji: 7.3,
  Kabulwarnamyo: 6.8,
  Madjinbardi: 7.4,
  Mumukala: 8,
  "Harts Range": 7.2,
  Nullywah: 11.4,
  "Mud Springs": 6.8,
  Mandangala: 12.8,
  "Iga Warta": 7.8,
  "Leigh Creek": 6.9,
  Copley: 5.9,
  Umoona: 9.5,
  Dunjiba: 12.88,
  "Turkey Bore": 9.2,
  Umuwa: 7.3,
  Kalka: 7.2,
  Angatja: 10,
  Munthamar: 15.95,
  Kanpa: 9.77,
  "Trucking Yards": 12.2,
  "Mount Nancy": 11.8,
  "Lhenpe Artnwe": 12.8,
  Mpwetyerre: 11.1,
  Yunyarinyi: 6.9,
  Alcoota: 12.3,
  "Ankerrapw - Utopia": 9.9,
  Ankerrapw: 9.9,
  "Ingkwelaye  - Utopia": 10,
  Ingkwelaye: 10,
  "Atnarara - Utopia": 8.8,
  Atnarara: 8.8,
  "Apungalindum - Utopia": 8.6,
  "Antarringinya - Utopia": 8,
  Antarringinya: 7.15,
  Apungalindum: 8.6,
  "Arawerr - Utopia": 8.1,
  Artekerre: 8.5,
  Arawerr: 8.1,
  "Aniltji - Utopia": 7.4,
  Aniltji: 7.4,
  "Creek Camp": 8.7,
  "Murray Downs": 7.6,
  Wutunugurra: 9.87,
  Marlinja: 11.3,
  "Police Lagoon": 9.6,
  "Campbell Springs": 8.4,
  "Burren Junction": 6,
  Milyakburra: 7.8,
  Wurreranginy: 6.1,
  "Bartalumba Bay": 6.7,
  Yungngora: 10,
  Woodgamia: 9.3,
  Leonora: 6.15,
  Burrum: 8,
  Cheeditha: 9.1,
  Mingullatharndo: 7.5,
  "Gooda Binya": 8.7,
  "Five Mile": 9,
  Kupartiya: 8.1,
  Lundja: 9.6,
  Ngumban: 7.8,
  "Galeru Gorge": 7.4,
  Bidijul: 7.3,
  Moongardie: 7.1,
  Lingara: 7.4,
  Jodetluk: 7,
  Woolaning: 6.8,
  "Railway Bore": 7.2,
  Kalkadoon: 6.6,
  Karnparrmi: 15.9,
  Wuggubun: 6.8,
  Kybrook: 7.9,
  "Bloomfield River": 11.9,
  Marton: 8.8,
  Injinoo: 9.1,
  Seisia: 8.7,
  Mimili: 6,
  "Laynhapuy Homelands": 7.4,
  Buymarr: 8,
  Djarrakpi: 7,
  // "Birany Birany": 7.1,
  Raymangirr: 7.8,
  Rorruwuy: 7.2,
  Mapuru: 7,
  Gawa: 6.9,
  Yangunbi: 7.4,
  Nalyindi: 7,
  Gurrumuru: 6.8,
  Gurkawuy: 7.1,
  Bawaka: 6.5,
  Mirrngatja: 6.6,
  Barrkira: 6,
  Woodycupaldiya: 6.5,
  Balma: 6.8,
  "Ban thula": 6.3,
  Jimbalakudunj: 6.8,
  "Mount Allan": 6.8,
  "Wallace Rockhole": 6.3,
  Mbunghara: 7,
  Watinuma: 6.5,
  Weilmoringle: 6.2,
  Pilliga: 6.3,
  Penong: 6.7,
  Scotdesco: 6.3,
  Sandstone: 5.8,
  Innawonga: 6.1,
  Walytjatjata: 6.5,
  Lylentye: 7.4,
  Yalukun: 7.1,
  "Wandawuy-Boruwuy": 7.1,
  Garrthalala: 7.1,
  Langarra: 7.1,
  Bunhunura: 8,
  Aileron: 10,
};
