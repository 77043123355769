import React, { useEffect } from "react";
import Map from "./components/map";
import { connect } from "react-redux";
import {
  selectActiveCommunity,
  fetchData as _fetchData,
  setActiveCommunity as _setActiveCommunity,
  setCommunityValue as _setCommunityValue,
  setIsMobile as _setIsMobile,
  setIsCommunityVersion as _setIsCommunityVersion,
  setFilters as _setFilters,
} from "./ducks";
import SearchArea from "./components/search-area";
import LanguagesArea from "./components/languages-area";
import styles from "./App.module.scss";
import useMediaQuery from "./hooks/useMediaQuery";
import CommunityDetails from "./components/community-details";
import AppButton from "./components/app-button";
import useWindowSize from "./hooks/useWindowSize";

const filters = [
  { label: "Book Supply", key: "bookSupplyOrgs", active: false },
  { label: "Book Buzz", key: "bookBuzzServiceOrgs", active: false },
  { label: "Publishing Projects", key: "books", active: false },
  { label: "Upcoming Events", key: "events", active: false },
];

function App({
  fetchData,
  activeCommunity,
  setActiveCommunity,
  setCommunityValue,
  setIsMobile,
  isCommunityVersion,
  setFilters,
  setIsCommunityVersion,
}) {
  const { isMobile } = useMediaQuery();
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    fetchData(isCommunityVersion);
  }, [fetchData, isCommunityVersion]);

  // Configure initial state based on whether it's the community version
  // Community version has an extra filter (events) that public version doesn't have
  useEffect(() => {
    setIsCommunityVersion(isCommunityVersion);
    const showingFilters = isCommunityVersion
      ? filters
      : filters.filter((f) => f.key !== "events");
    setFilters(showingFilters);
  }, [isCommunityVersion, setIsCommunityVersion, setFilters]);

  // Sometimes 'isMobile' is needed for global selectors
  useEffect(() => {
    setIsMobile(isMobile);
  }, [isMobile, setIsMobile]);

  return (
    <div className={`${styles.App}`}>
      <div className={styles.AppContent}>
        <SearchArea isCommunityVersion={isCommunityVersion} />
        {windowHeight && <Map windowHeight={windowHeight} />}
        {activeCommunity && isMobile && (
          <div className={styles.communityDetailsWrapper}>
            <CommunityDetails />
            <div style={{ padding: "0px 0px 48px 0px" }}>
              <AppButton
                onClick={() => {
                  setActiveCommunity("");
                  setCommunityValue(null);
                }}
                label="Clear community selection"
                color="orange"
                filled={false}
                crossIcon={true}
              />
            </div>
          </div>
        )}
        <LanguagesArea />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    activeCommunity: selectActiveCommunity(state),
  };
};

const mapDispatchToProps = {
  fetchData: _fetchData,
  setActiveCommunity: _setActiveCommunity,
  setCommunityValue: _setCommunityValue,
  setIsMobile: _setIsMobile,
  setIsCommunityVersion: _setIsCommunityVersion,
  setFilters: _setFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
