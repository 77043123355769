import React from "react";
import styles from "./language-card.module.scss";
import { ChevronRight } from "../icons";

const MAX_LABEL_LEN = 21;

// Group resources by type
const reduceResources = (resourceList) => {
  return resourceList.reduce((acc, curr) => {
    const type = curr.productType ? curr.productType.toLowerCase() : "book";
    if (acc[type]) {
      return {
        ...acc,
        [type]: [...acc[type], curr],
      };
    } else {
      return {
        ...acc,
        [type]: [curr],
      };
    }
  }, {});
};

const LanguageCard = ({ language }) => {
  const { books, languageLanguageName: languageName, ilfUrl } = language;

  const numBooks = books.length;
  const hasBooks = numBooks > 0;
  const shortName =
    languageName.length > MAX_LABEL_LEN
      ? languageName.slice(0, MAX_LABEL_LEN) + "..."
      : languageName;
  const resourcesByType = reduceResources(books);

  return (
    <div className={styles.languageCard}>
      <div>
        {hasBooks ? (
          <a
            href={
              ilfUrl ||
              "https://www.indigenousliteracyfoundation.org.au/languages"
            }
            target="_blank"
            rel="noreferrer"
            className={styles.languageLink}
            title={`Resources in ${languageName}`}
          >
            <span className={styles.languageLinkLabel}>{shortName}</span>
            <ChevronRight style={{ marginLeft: 8 }} />
          </a>
        ) : (
          <span className={styles.languageLink}>
            <span className={styles.languageLinkLabel}>{shortName}</span>
          </span>
        )}
      </div>
      {Object.entries(resourcesByType).map(([type, res], i) => {
        return (
          <div key={i}>
            <span className={styles.numResources}>{`${res.length} ${
              res.length === 1 ? type : type + "s"
            }`}</span>
          </div>
        );
      })}
    </div>
  );
};

export default LanguageCard;
