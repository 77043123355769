import React from "react";
import styles from "./details-list.module.scss";
import { ChevronRight } from "../icons";

const DetailsList = ({ header, items, noDataMessage, ListIcon, subTitle }) => {
  const { label, href } = header;
  return (
    <div className={styles.detailsList}>
      <h5 className={styles.detailsListHeader}>
        <span className={styles.detailsListHeaderLabel}>
          {href ? (
            <a
              href={href}
              target="_blank"
              rel="noreferrer"
              className={styles.detailsListLink}
            >
              <span>{label}</span>
              <ChevronRight style={{ marginLeft: 8 }} />
            </a>
          ) : (
            <span>{label}</span>
          )}
        </span>
      </h5>
      <div className={styles.detailsListContent}>
        {items.length ? (
          <ul>
            {items.map((item, i) => {
              const { label, href } = item;
              return (
                <li key={i}>
                  <div className={styles.detailListTextEntry}>
                    {ListIcon && (
                      <span className={styles.listIcon}>
                        <ListIcon />
                      </span>
                    )}
                    <div style={{ display: "inline-block" }}>
                      {href ? (
                        <span>
                          <a
                            className={styles.regularListItemLink}
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {label}
                          </a>
                        </span>
                      ) : (
                        <span>{label}</span>
                      )}
                      {subTitle && (
                        <div
                          className={styles.displayItemSubTitle}
                          style={{ marginTop: href ? 5 : 1 }}
                        >
                          {subTitle}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <span className={styles.noDataMessage}>{noDataMessage}</span>
        )}
      </div>
    </div>
  );
};

export default DetailsList;
