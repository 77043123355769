import React from "react";

const SentenceList = ({ stringArray, listTag: ListTag }) => {
  return (
    <>
      {stringArray.map((l, i, arr) => {
        if (arr.length === 1) {
          return <ListTag key={i}>{l}</ListTag>;
        } else if (i < arr.length - 2) {
          return (
            <React.Fragment key={i}>
              <ListTag>{l}</ListTag>
              <span>, </span>
            </React.Fragment>
          );
        } else if (i < arr.length - 1) {
          return <ListTag key={i}>{l}</ListTag>;
        } else {
          return (
            <React.Fragment key={i}>
              <span> and </span>
              <ListTag>{l}</ListTag>
            </React.Fragment>
          );
        }
      })}
      <span>.</span>
    </>
  );
};

export default SentenceList;
