export const extractInnerHTMLFromLink = (htmlString) => {
  if (!htmlString) {
    return null;
  } else if (!htmlString.includes("<a")) {
    return htmlString;
  } else {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    return div.childNodes[0] ? div.childNodes[0].innerText : null;
  }
};
