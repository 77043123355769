import React from "react";
import { connect } from "react-redux";
import {
  setActiveLanguageById as _setActiveLanguageById,
  setLanguageValue as _setLanguageValue,
} from "../../ducks";
import { Link } from "react-scroll";
import { DoubleChevronDown } from "../icons";
import styles from "./language-links.module.scss";

const LanguageLinks = ({
  languages,
  setActiveLanguageById,
  setLanguageValue,
}) => {
  return (
    <span>
      {languages.map((l, i) => (
        <div className={styles.languageLinkContainer} key={i}>
          <Link
            to="language-resources"
            smooth={true}
            duration={500}
            onClick={() => {
              const selectValue = {
                value: l.languageId,
                label: l.languageLanguageName,
                alternativeNames: l.alternativeNames,
              };
              setActiveLanguageById(l.languageId);
              setLanguageValue(selectValue);
            }}
          >
            <div className={styles.languageScrollLink}>
              <span>{l.languageLanguageName}</span>
              <DoubleChevronDown style={{ marginLeft: 8 }} />
            </div>
          </Link>
        </div>
      ))}
    </span>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  setActiveLanguageById: _setActiveLanguageById,
  setLanguageValue: _setLanguageValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageLinks);
