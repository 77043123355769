import React, { useState, useEffect, useRef } from "react";
import ReactMapGL, {
  HTMLOverlay,
  FlyToInterpolator,
  WebMercatorViewport,
} from "react-map-gl";
import { easeCubic } from "d3-ease";
import { MapDot, MapDotActive, AustraliaIcon } from "../icons";
import styles from "./map.module.scss";
import "./map.css";
import CommunitySidebar from "../community-sidebar";
import "mapbox-gl/dist/mapbox-gl.css";
import { connect } from "react-redux";
import {
  fetchData as _fetchData,
  selectActiveCommunities,
  setActiveCommunity as _setActiveCommunity,
  selectActiveCommunity,
  setCommunityValue as _setCommunityValue,
  setHoveredCommunity as _setHoveredCommunity,
  selectHoveredCommunity,
  selectIsCommunityVersion,
} from "../../ducks";
import Select from "react-select";
import { customDropdownStyles } from "../../style/variables";
import useMediaQuery from "../../hooks/useMediaQuery";
import useComponentSize from "@rehooks/component-size";
import {
  AUSTRALIA_VIEWPORT,
  AUSTRALIA_MOBILE_VIEWPORT,
  AUSTRALIA_PUBLIC_MOBILE_VIEWPORT,
  zoomOptions,
} from "./viewports";
import { SIDEBAR_WIDTH } from "../../style/variables";
import { importance } from "./importance";
import {
  numCommunitiesInBounds,
  getZoomOffsetForWindowHeight,
  getMapHeight,
  overlaps,
  getXOffset,
} from "./map-helpers";
import MapNavigationControl from "../map-navigation-control";
import {
  CopyrightMessage,
  GetInTouchMessage,
  NumCommunitiesMessage,
} from "./MapOverlayMessages";

// Flag to turn mobile panning on/off
const MOBILE_PANNABLE = true;

const flyTo = {
  transitionDuration: 1900,
  transitionInterpolator: new FlyToInterpolator(),
  transitionEasing: easeCubic,
};

const Map = ({
  communities,
  setActiveCommunity,
  activeCommunity,
  setCommunityValue,
  setHoveredCommunity,
  hoveredCommunity,
  windowHeight,
  isCommunityVersion,
}) => {
  const { isMobile } = useMediaQuery();
  const panningDisabled = isMobile && !MOBILE_PANNABLE;

  const mobileViewport = isCommunityVersion
    ? AUSTRALIA_MOBILE_VIEWPORT
    : AUSTRALIA_PUBLIC_MOBILE_VIEWPORT;

  const mapViewport = isMobile ? mobileViewport : AUSTRALIA_VIEWPORT;

  const defaultZoom =
    mapViewport.zoom + getZoomOffsetForWindowHeight(windowHeight);
  const MIN_ZOOM = defaultZoom - 0.1;

  const desktopViewport = {
    ...AUSTRALIA_VIEWPORT,
    zoom: defaultZoom,
  };

  const defaultViewport = isMobile ? mobileViewport : desktopViewport;

  const COMMUNITY_ZOOM_LEVEL = isMobile ? 7.5 : 7.5;
  const NAMES_VISIBLE_ZOOM = isMobile ? 5.5 : 5.5;
  const DOTS_VISIBLE_ZOOM = isMobile ? 3 : 1;

  let mapRef = useRef(null);
  let revealRefs = useRef([]);
  revealRefs.current = [];

  let { width, height } = useComponentSize(mapRef);

  const [viewport, setViewport] = useState(defaultViewport);
  const [numShowingCommunities, setNumShowingCommunities] = useState(
    communities.length
  );
  const [zoomOpts, setZoomOpts] = useState(zoomOptions);

  useEffect(() => {
    const offset = getZoomOffsetForWindowHeight(windowHeight);
    const newZoomOptions = zoomOptions.map((z) => {
      return { ...z, value: { ...z.value, zoom: z.value.zoom + offset } };
    });
    setZoomOpts(newZoomOptions);
  }, [windowHeight]);

  // Harry: better way would be to put id in state, use memo to make sure you have the object
  const activeCommunityId = activeCommunity ? activeCommunity.accountId : "";
  const activeLat = activeCommunity ? activeCommunity.geolocationLatitude : "";
  const activeLong = activeCommunity
    ? activeCommunity.geolocationLongitude
    : "";

  // If a new active community has been set, fly to it.
  useEffect(() => {
    if (isMobile && !activeCommunityId) {
      setViewport(mobileViewport);
    } else if (activeLat && activeLong) {
      setViewport((v) => {
        const shouldZoom = v.zoom < COMMUNITY_ZOOM_LEVEL;
        const newZoom = shouldZoom ? COMMUNITY_ZOOM_LEVEL : v.zoom;

        const webMercatorViewport = new WebMercatorViewport({
          ...v,
          zoom: newZoom,
          height: height,
          width: width,
        });

        // We need the map center to take the sidebar into account on desktop
        const centerX = isMobile ? width / 2 : (width - SIDEBAR_WIDTH) / 2;
        const centerY = height / 2;
        const opts = {
          lngLat: [activeLong, activeLat],
          pos: [centerX, centerY],
        };
        const [newLongitude, newLatitude] =
          webMercatorViewport.getMapCenterByLngLatPosition(opts);

        return {
          latitude: newLatitude,
          longitude: newLongitude,
          zoom: newZoom,
          ...flyTo,
        };
      });
    }
  }, [
    activeLat,
    activeLong,
    activeCommunityId,
    isMobile,
    COMMUNITY_ZOOM_LEVEL,
    height,
    width,
    mobileViewport,
  ]);

  // When the viewport or communities change,
  // recalculate num communities in view
  useEffect(() => {
    if (!viewport.latitude) {
      setNumShowingCommunities(communities.length);
    }

    if (!isMobile && viewport.latitude) {
      const webMercatorViewport = new WebMercatorViewport({
        ...viewport,
        height: height,
        width: width,
      });

      // Set num showing communities based on bounds
      const bounds = webMercatorViewport.getBounds();
      const communitiesInView = numCommunitiesInBounds(bounds, communities);
      setNumShowingCommunities(communitiesInView);
    }
  }, [viewport, communities, height, width, isMobile]);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  const getIsHoveredCommunity = (element, hoveredCommunity) => {
    return element.id === hoveredCommunity;
  };

  // Collision detection
  // This might be unnecessarily optimised
  // It only checks for overlaps with hovered and active communities
  // (Which change the label size and position)
  // Since it's assumed that 'importance' dictates the visibility otherwise
  useEffect(() => {
    const activeCommunityLabel = activeCommunity
      ? revealRefs.current.find((r) => r.id === activeCommunity.accountId)
      : null;
    const hoveredCommunityLabel = hoveredCommunity
      ? revealRefs.current.find((r) => {
          return r.id === hoveredCommunity;
        })
      : null;

    const activeCommunityRect = activeCommunityLabel
      ? activeCommunityLabel.getBoundingClientRect()
      : null;

    const hoveredCommunityRect = hoveredCommunityLabel
      ? hoveredCommunityLabel.getBoundingClientRect()
      : null;

    for (let i = 0; i < revealRefs.current.length; i++) {
      const element = revealRefs.current[i];
      const bc = element.getBoundingClientRect();

      const isHoveredCommunity = getIsHoveredCommunity(
        element,
        hoveredCommunity
      );
      const isActiveCommunity =
        activeCommunity && element.id === activeCommunity.accountId;

      const overlapsHoveredCommunity =
        hoveredCommunity &&
        !isHoveredCommunity &&
        overlaps(bc, hoveredCommunityRect);
      const overlapsActiveCommunity =
        !isActiveCommunity && overlaps(bc, activeCommunityRect);

      if (isHoveredCommunity) {
        element.classList.add("no-overlap");
        element.classList.remove("overlap");
      } else if (isActiveCommunity && overlapsHoveredCommunity) {
        element.classList.add("overlap");
        element.classList.remove("no-overlap");
      } else if (overlapsActiveCommunity || overlapsHoveredCommunity) {
        element.classList.add("overlap");
        element.classList.remove("no-overlap");
      } else {
        element.classList.add("no-overlap");
        element.classList.remove("overlap");
      }
    }
  }, [activeCommunity, revealRefs, hoveredCommunity]);

  return (
    <div
      className={`${styles.map} ${
        isCommunityVersion ? "community-version" : "public-version"
      }`}
      style={{
        height: isMobile
          ? isCommunityVersion
            ? 330
            : 300 // Less because no contact msg
          : getMapHeight(windowHeight),
      }}
      ref={mapRef}
    >
      <ReactMapGL
        {...viewport}
        latitude={viewport.latitude || defaultViewport.latitude}
        longitude={viewport.longitude || defaultViewport.longitude}
        width={"100%"}
        height={"100%"}
        getCursor={() => {
          return panningDisabled ? "default" : "grab";
        }}
        onViewportChange={(viewState, interactionState, oldViewState) => {
          const { latitude, longitude, zoom } = viewState;
          const canZoom = zoom >= MIN_ZOOM;
          const newZoom = canZoom ? zoom : MIN_ZOOM;
          const newLat = canZoom ? latitude : oldViewState.latitude;
          const newLong = canZoom ? longitude : oldViewState.longitude;
          // Just using interactionState randomly here otherwise it's unused
          // if (pannable && interactionState && latitude) {
          if (!panningDisabled && interactionState && latitude) {
            setViewport({
              latitude: newLat,
              longitude: newLong,
              zoom: newZoom,
            });
          }
        }}
        mapStyle={"mapbox://styles/smallmultiples/ckjrsorsv704w19lo8cllaz79"}
      >
        {communities && (
          <HTMLOverlay
            redraw={({ project }) => {
              let svgGroups = communities.map((c, i) => {
                const [x, y] = project([
                  c.geolocationLongitude,
                  c.geolocationLatitude,
                ]);
                const isActive =
                  activeCommunity && c.accountId === activeCommunity.accountId;
                const isHovered = hoveredCommunity === c.accountId;
                const appearsActive = isActive || isHovered;

                const offset = isActive ? 6 : 5;

                const communityImportance = importance[c.accountName]
                  ? importance[c.accountName]
                  : NAMES_VISIBLE_ZOOM;

                const markerHiddenMobile = panningDisabled && !isActive;

                const labelIsVisible =
                  (viewport.zoom >= communityImportance || appearsActive) &&
                  !markerHiddenMobile;

                const markerIsVisible =
                  (viewport.zoom > DOTS_VISIBLE_ZOOM || appearsActive) &&
                  !markerHiddenMobile;

                const marker = (
                  <div
                    id={`marker-${c.accountId}`}
                    className={`${styles.mapMarkerWrapper} ${
                      isActive ? styles.active : styles.inactive
                    }`}
                    style={{
                      transform: `translate(${x - offset}px, ${y - offset}px)`,
                      display: markerIsVisible ? "block" : "none",
                    }}
                  >
                    <div
                      style={{ marginTop: offset * -1 }}
                      className={styles.communityWrapper}
                      onMouseEnter={() => {
                        if (isMobile) {
                          setActiveCommunity(c.accountId);
                        } else {
                          setHoveredCommunity(c.accountId);
                        }
                      }}
                      onMouseLeave={() => {
                        setHoveredCommunity("");
                      }}
                      onClick={() => {
                        setActiveCommunity(c.accountId);
                      }}
                    >
                      {appearsActive ? <MapDotActive /> : <MapDot />}
                    </div>
                  </div>
                );

                const labelOffset = appearsActive ? 28 : 22;
                const xOffset = getXOffset(c.accountName);

                const showingClass = labelIsVisible ? "showing" : "not-showing";

                const label = (
                  <div
                    id={c.accountId}
                    ref={addToRefs}
                    className={`${styles.nameWrapper} ${showingClass}`}
                    style={{
                      transform: `translate(${x - xOffset}px, ${
                        y - labelOffset
                      }px)`,
                      cursor: labelIsVisible ? "pointer" : "default",
                      display: labelIsVisible ? "block" : "none",
                    }}
                    onMouseEnter={() => {
                      if (labelIsVisible) {
                        setHoveredCommunity(c.accountId);
                      }
                    }}
                    onMouseLeave={() => {
                      if (labelIsVisible) {
                        setHoveredCommunity("");
                      }
                    }}
                    onClick={() => {
                      if (labelIsVisible) {
                        setActiveCommunity(c.accountId);
                      }
                    }}
                  >
                    <div
                      className={`${styles.communityName} ${
                        appearsActive
                          ? styles.activeLabel
                          : styles.inactiveLabel
                      }`}
                    >
                      {c.accountName}
                    </div>
                  </div>
                );

                return (
                  <React.Fragment key={c.accountId}>
                    {marker}
                    {label}
                  </React.Fragment>
                );
              });

              return svgGroups;
            }}
          ></HTMLOverlay>
        )}
      </ReactMapGL>
      {/* Absolute position elements that go on top of the map */}
      {!panningDisabled && (
        <MapNavigationControl
          onZoomInClick={() => {
            setViewport({ ...viewport, zoom: viewport.zoom + 1.5, ...flyTo });
          }}
          onZoomOutClick={() => {
            setViewport({ ...viewport, zoom: viewport.zoom - 1.5, ...flyTo });
          }}
        />
      )}
      {isMobile &&
        !panningDisabled &&
        viewport &&
        viewport.zoom > mobileViewport.zoom && (
          <div
            className={styles.zoomToAusBtn}
            onClick={() => {
              setViewport({ ...mobileViewport, ...flyTo });
            }}
          >
            <AustraliaIcon />
          </div>
        )}
      {activeCommunity && !isMobile && (
        <CommunitySidebar
          onClose={() => {
            setActiveCommunity("");
            setCommunityValue(null);
          }}
        />
      )}
      <div className={styles.zoomToAreaContainer}>
        <Select
          options={zoomOpts}
          placeholder="Zoom to area"
          styles={customDropdownStyles}
          onChange={(option) => {
            setViewport({
              ...viewport,
              ...option.value,
              ...flyTo,
            });
          }}
        />
      </div>
      <img
        src={`${process.env.REACT_APP_ASSET_PATH}images/curve-other-ways-bottom.png`}
        className={styles.curveOrangeMapTop}
        alt=""
      />
      <img
        src={`${process.env.REACT_APP_ASSET_PATH}images/curve-counter-top.png`}
        className={styles.curveBlueMapBase}
        alt=""
      />
      {isCommunityVersion ? (
        <GetInTouchMessage isMobile={isMobile} />
      ) : (
        <CopyrightMessage />
      )}

      <NumCommunitiesMessage numShowingCommunities={numShowingCommunities} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    communities: selectActiveCommunities(state),
    activeCommunity: selectActiveCommunity(state),
    hoveredCommunity: selectHoveredCommunity(state),
    isCommunityVersion: selectIsCommunityVersion(state),
  };
};

const mapDispatchToProps = {
  fetchData: _fetchData,
  setActiveCommunity: _setActiveCommunity,
  setCommunityValue: _setCommunityValue,
  setHoveredCommunity: _setHoveredCommunity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
