import React from "react";
import styles from "./app-button.module.scss";
import { CrossIcon } from "../icons";

const AppButton = ({ onClick, color, filled, label, crossIcon }) => {
  return (
    <button
      className={`${styles.appBtn} ${styles[color]} ${
        filled ? styles.filled : styles.outlined
      }`}
      onClick={onClick}
    >
      <span>{label}</span>
      {crossIcon && <CrossIcon style={{ marginLeft: 12 }} />}
    </button>
  );
};

export default AppButton;
