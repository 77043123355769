export const numCommunitiesInBounds = (bounds, communities) => {
  const [swCorner, neCorner] = bounds;
  const [minLon, minLat] = swCorner;
  const [maxLon, maxLat] = neCorner;

  const communitiesInView = communities.filter((c) => {
    return (
      c.geolocationLongitude > minLon &&
      c.geolocationLongitude < maxLon &&
      c.geolocationLatitude > minLat &&
      c.geolocationLatitude < maxLat
    );
  });

  return communitiesInView.length;
};

const WindowHeight = {
  SMALL: 750,
  LARGE: 1000,
};

export const getZoomOffsetForWindowHeight = (windowHeight) => {
  if (windowHeight > WindowHeight.LARGE) {
    return 0;
  } else if (windowHeight < WindowHeight.SMALL) {
    return -0.2;
  } else {
    return 0;
  }
};

// Change map height depending on window height
export const getMapHeight = (windowHeight) => {
  if (windowHeight < WindowHeight.SMALL) {
    return 530;
  } else if (windowHeight > WindowHeight.LARGE) {
    return 638;
  } else {
    return 638;
  }
};

export const overlaps = (boundingRect1, boundingRect2) => {
  if (!boundingRect1 || !boundingRect2) {
    return false;
  }
  var overlap = !(
    boundingRect1.right < boundingRect2.left ||
    boundingRect1.left > boundingRect2.right ||
    boundingRect1.bottom < boundingRect2.top ||
    boundingRect1.top > boundingRect2.bottom
  );
  return overlap;
};

// I'm sure this would be better to do with refs
export const getXOffset = (label) => {
  const x = Math.floor(label.length * 3.5);
  const extraWords = -1 + label.split(" ").length;
  return x + extraWords;
};
