import React from "react";
import styles from "./messages.module.scss";

export const NoResourcesMessage = ({ activeLanguage }) => {
  return (
    <div className={styles.noResourcesMsg}>
      <span>
        <span>No ILF resources for </span>
        <span className={styles.chosenLanguage}>
          {activeLanguage
            ? activeLanguage.languageLanguageName
            : "this language"}
        </span>
        <span>. </span>
      </span>
    </div>
  );
};

export const NoLanguageDataMessage = ({ activeCommunity }) => {
  return (
    <div className={styles.noResourcesMsg}>
      <span>
        <span>
          ILF does not currently have data about languages associated with{" "}
        </span>
        <span className={styles.chosenLanguage}>
          {activeCommunity ? activeCommunity.accountName : "this community"}
        </span>
        <span>, but you can search or select from the list below.</span>
      </span>
    </div>
  );
};
