import React from "react";
import styles from "./language-search.module.scss";
import { connect } from "react-redux";
import { selectLanguageInputValue } from "../../ducks";
import Highlighter from "react-highlight-words";

// Custom option component, displays alternative names
const Option = ({ label, value, data, innerProps, languageInputValue }) => {
  const { alternativeNames } = data;
  return (
    <div className={styles.searchOption} {...innerProps} key={value}>
      <span>
        <Highlighter
          searchWords={[languageInputValue]}
          textToHighlight={label}
          highlightClassName={styles.highlight}
        />
      </span>
      {alternativeNames && (
        <div className={styles.alsoKnownAs}>
          <span>Also known as </span>
          <span className={styles.alternativeNames}>
            <Highlighter
              searchWords={[languageInputValue]}
              textToHighlight={alternativeNames}
              highlightClassName={styles.highlight}
            />
          </span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageInputValue: selectLanguageInputValue(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Option);
