import React from "react";
import qs from "qs";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider as StoreProvider } from "react-redux";

window.smuEmbedProjectMap = function (element, opts) {
  const params = qs.parse(window.location.search.slice(1));

  const emptyParams = params.communityVersion === undefined;

  let isCommunityVersion = false;

  // Embedded community site
  if (!opts && emptyParams) {
    isCommunityVersion = true;
  }
  // Embedded public site
  else if (opts && opts.isPublic) {
    isCommunityVersion = false;
  }
  // Embedded community site
  else if (opts && !opts.isPublic) {
    isCommunityVersion = true;
  }
  // Raw URL community version
  else if (!opts && params.communityVersion === "true") {
    isCommunityVersion = true;
  }

  const el =
    element instanceof HTMLElement ? element : document.querySelector(element);
  const render = () =>
    ReactDOM.render(
      <React.StrictMode>
        <StoreProvider store={store}>
          <App isCommunityVersion={isCommunityVersion} />
        </StoreProvider>
      </React.StrictMode>,
      el
    );
  render();
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./App", render);
  }
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
