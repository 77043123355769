// Style constants needed across different files

export const SIDEBAR_WIDTH = 360;
export const SIDEBAR_RIGHT_PERCENT = 10;

// Styles object for react-select
const controlStyles = (styles) => ({
  ...styles,
  borderRadius: 0,
  paddingLeft: 36,
  minHeight: 40,
  height: 40,
  // paddingTop: 3,
  borderWidth: 0,
  cursor: "text",
  display: "flex !important",
  alignItems: "center",
});

const dropdownControlStyles = (styles) => ({
  ...styles,
  // paddingTop: 3,
  borderRadius: 0,
  borderWidth: 0,
  cursor: "pointer",
  display: "flex !important",
});

const indicatorsContainerStyles = (styles) => ({
  ...styles,
  display: "flex !important",
});

const menuStyles = (styles) => ({
  ...styles,
  borderRadius: 0,
  padding: 0,
  zIndex: 11,
  cursor: "pointer",
});

const menuListStyles = (styles) => ({
  ...styles,
  padding: 0,
  cursor: "pointer",
});

const searchDropdownIndicatorStyles = (styles) => ({
  ...styles,
  display: "none !important",
});

const optionStyles = (styles) => ({
  ...styles,
  cursor: "pointer",
});

const clearIndicatorStyles = (styles) => ({
  ...styles,
  cursor: "pointer",
});

const indicatorSeparatorStyles = (styles) => ({ ...styles, display: "none" });

const valueContainerStyles = (styles) => ({
  ...styles,
  display: "flex !important",
  alignItems: "center",
  paddingTop: 0,
  paddingBottom: 0,
});

const placeholderStyles = (styles) => ({
  ...styles,
  display: "flex !important",
  paddingTop: 3,
});

export const customSearchbarStyles = {
  control: controlStyles,
  menu: menuStyles,
  menuList: menuListStyles,
  dropdownIndicator: searchDropdownIndicatorStyles,
  indicatorSeparator: indicatorSeparatorStyles,
  clearIndicator: clearIndicatorStyles,
  indicatorsContainer: indicatorsContainerStyles,
  valueContainer: valueContainerStyles,
  placeholder: placeholderStyles,
};

const containerStyles = (styles) => ({
  ...styles,
  display: "block",
});

export const customDropdownStyles = {
  control: dropdownControlStyles,
  menu: menuStyles,
  menuList: menuListStyles,
  placeholder: placeholderStyles,
  indicatorSeparator: indicatorSeparatorStyles,
  dropdownIndicator: searchDropdownIndicatorStyles,
  option: optionStyles,
  container: containerStyles,
  valueContainer: valueContainerStyles,
  indicatorsContainer: indicatorsContainerStyles,
};
