import React, { useMemo } from "react";
import styles from "./community-details.module.scss";
import { connect } from "react-redux";
import { selectActiveCommunity, selectIsCommunityVersion } from "../../ducks";
import DetailsList from "../details-list";
import { BookIcon, CalendarIcon } from "../icons";
import LanguageLinks from "../language-links";
import { extractInnerHTMLFromLink } from "../../modules/formatters";
import { strings } from "../../strings";
import { uniq } from "lodash";
import BookList from "../details-list/BookList";
const { urls } = strings;

const NoDataLink = ({ href, linkText }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={styles.noDataMessageLink}
    >
      {linkText}
    </a>
  );
};

const CommunityDetails = ({ activeCommunity, isCommunityVersion }) => {
  const {
    alternativeNames,
    languages,
    events,
    books,
    accountName,
    bookSupplyOrgs,
    bookBuzzServiceOrgs,
  } = activeCommunity;
  const hasLanguages = languages.length > 0;
  const hasEvents = events.length > 0;
  const hasBooks = books.length > 0;
  const hasBookBuzz = bookBuzzServiceOrgs.length > 0;
  const hasBookSupply = bookSupplyOrgs.length > 0;

  // List of unique languages which the community has published books in
  const bookLanguages = useMemo(() => {
    const languageList = books.flatMap((b) =>
      b.language ? b.language.split(", ").map((l) => l.trim()) : []
    );
    return uniq(languageList);
  }, [books]);

  // Put books which are available in online shop at the top of the list
  // (only if they have a Shopify URL)
  const bookList = useMemo(() => {
    const sortedBooks = books.sort((a, b) =>
      a.onlineShop && a.shopifyUrl && !b.onlineShop ? -1 : 1
    );
    return sortedBooks;
  }, [books]);

  return (
    <div className={styles.communityDetails}>
      {activeCommunity && (
        <div>
          <div className={styles.communityDetailsBlock}>
            <h4>
              <span className={styles.communityName}>{accountName}</span>
            </h4>
            {alternativeNames && (
              <div className={styles.alsoKnownAs}>
                <span>Also known as </span>
                <span className={styles.alternativeNames}>
                  {alternativeNames}
                </span>
              </div>
            )}
          </div>
          {!isCommunityVersion && (
            <div
              className={`${styles.communityDetailsBlock} ${styles.publicDetailsBlock}`}
            >
              <p>
                <span>Our </span>
                {hasBookSupply && <a href={urls.bookSupply}>book supply</a>}
                {hasBookSupply && hasBookBuzz && <span> and </span>}
                {hasBookBuzz && <a href={urls.bookBuzz}>book buzz</a>}
                <span>
                  {hasBookSupply && hasBookBuzz
                    ? " programs are "
                    : " program is "}
                  available in
                </span>
                <strong> {accountName}</strong>.
              </p>
            </div>
          )}
          {isCommunityVersion && hasLanguages && (
            <div
              className={`${styles.communityDetailsBlock} ${styles.languagesBlock}`}
            >
              <h5>
                <span>Language(s)</span>
              </h5>
              <LanguageLinks languages={languages} />
            </div>
          )}
          {isCommunityVersion && hasEvents && (
            <DetailsList
              header={{
                label: "Upcoming events",
                href: null,
              }}
              ListIcon={CalendarIcon}
              items={events.map((e) => {
                return {
                  label: e.communityEventEventName,
                  href: e.communityEventEventUrl,
                };
              })}
              subTitle={events.map((e) => {
                const {
                  communityEventDateText: dateTxt,
                  communityEventLocation: loc,
                } = e;
                const hasSubTitle = dateTxt || loc;
                const divider = dateTxt && loc ? " - " : "";
                const str = `${dateTxt}${divider}${loc}`;
                return hasSubTitle ? str : "";
              })}
              noDataMessage="No upcoming events"
            />
          )}
          {isCommunityVersion && (
            <>
              <DetailsList
                header={{
                  label: "Books for everyone (Book Supply)",
                  href: "https://www.indigenousliteracyfoundation.org.au/book-supply",
                }}
                ListIcon={BookIcon}
                items={bookSupplyOrgs.map((o) => {
                  return { label: o.opportunityAccountNameAccountName };
                })}
                noDataMessage={
                  <span>
                    No organisations have ordered from Book Supply this year.
                    Learn about Book Supply{" "}
                    <NoDataLink
                      href="https://www.indigenousliteracyfoundation.org.au/book-supply"
                      linkText="here"
                    />
                    .
                  </span>
                }
              />
              <DetailsList
                header={{
                  label: "Little kids & books (Book Buzz)",
                  href: "https://www.indigenousliteracyfoundation.org.au/book-buzz",
                }}
                items={bookBuzzServiceOrgs.map((o) => {
                  return { label: o.serviceOrganisationsAccountName };
                })}
                ListIcon={BookIcon}
                noDataMessage={
                  <span>
                    No playgroups are registered. Learn about Book Buzz{" "}
                    <NoDataLink
                      href="https://www.indigenousliteracyfoundation.org.au/book-buzz"
                      linkText="here"
                    />
                    .
                  </span>
                }
              />
            </>
          )}
          {hasBooks && bookList && (
            <BookList
              header={{
                label: isCommunityVersion
                  ? "Books done by community (Publishing Projects)"
                  : "Publishing Projects",
                href: null,
              }}
              bookLanguages={bookLanguages}
              books={bookList
                .filter((b) => b) // Shouldn't be needed, but there have been data errors before
                .map((b) => {
                  return {
                    label: `${b.title}`,
                    buyLink: extractInnerHTMLFromLink(b.shopifyUrl),
                    ilfLink:
                      extractInnerHTMLFromLink(b.ilfUrl) ||
                      "https://www.indigenousliteracyfoundation.org.au/no-book-page",
                    extraText: `(${b.bookFormat}) - ${b.language}`,
                    image: b.coverUrl,
                  };
                })}
              isCommunityVersion={isCommunityVersion}
              noDataMessage="No books yet. Get in touch!"
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeCommunity: selectActiveCommunity(state),
    isCommunityVersion: selectIsCommunityVersion(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityDetails);
