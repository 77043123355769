import React from "react";
import { MinusIcon, PlusIcon } from "../icons";
import styles from "./map-navigation-control.module.scss";

const MapNavigationControl = ({ onZoomInClick, onZoomOutClick }) => {
  return (
    <div className={styles.navigationControl}>
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group ">
        <button
          className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in"
          type="button"
          title="Zoom In"
          onClick={onZoomInClick}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PlusIcon />
        </button>
        <button
          className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out"
          type="button"
          title="Zoom Out"
          onClick={onZoomOutClick}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MinusIcon />
        </button>
      </div>
    </div>
  );
};

export default MapNavigationControl;
