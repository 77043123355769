import React from "react";
import styles from "./details-list.module.scss";
import { ShoppingCartIcon } from "../icons";
import SentenceList from "../sentence-list";

const BookList = ({
  header,
  books,
  noDataMessage,
  isCommunityVersion,
  bookLanguages,
}) => {
  const { label, href } = header;
  const onlyEnglishBooks =
    bookLanguages.length === 1 && bookLanguages[0] === "English";

  return (
    <div className={styles.detailsList}>
      <h5 className={styles.detailsListHeader}>
        <span className={styles.detailsListHeaderLabel}>{label}</span>
      </h5>
      {!isCommunityVersion && !onlyEnglishBooks && (
        <p className={styles.publicBookDetails}>
          <span>We have helped the community to publish books in </span>
          <SentenceList stringArray={bookLanguages} listTag={"strong"} />
        </p>
      )}

      <div className={styles.detailsListContent}>
        {books.length ? (
          <ul>
            {books.map((book, i, arr) => {
              const { label, ilfLink, buyLink, extraText, image } = book;
              const commerciallyAvailable = !isCommunityVersion && buyLink;
              const isFirstUnavailableBook =
                (!isCommunityVersion &&
                  !buyLink &&
                  arr[i - 1] &&
                  arr[i - 1].buyLink) ||
                (i === 0 && !buyLink);

              const className = isCommunityVersion
                ? "communityBook"
                : buyLink
                ? "commercialBook"
                : "unavailableBook";

              return (
                <li
                  key={i}
                  className={`${styles[className]} ${styles.bookListItem}`}
                >
                  <div style={{ marginTop: i > 0 ? 24 : 0 }}>
                    {isFirstUnavailableBook && !isCommunityVersion && (
                      <div>
                        <h6 className={styles.booklistSubheader}>
                          Books for community and language speakers
                        </h6>
                        <p className={styles.publicBookDetails}>
                          Please note; only some titles are publicly available
                          to purchase. For all commercially available titles
                          look in our{" "}
                          <a href="https://shop.ilf.org.au/">shop</a>.
                        </p>
                      </div>
                    )}

                    {isCommunityVersion && ilfLink && (
                      <div>
                        <div>
                          <a
                            className={styles.boldListItemLink}
                            href={ilfLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {label}
                          </a>
                        </div>
                      </div>
                    )}
                    {commerciallyAvailable && (
                      <div className={`${styles.buyBookHeader}`}>
                        <strong className={styles.detailsListBookName}>
                          {label}
                        </strong>
                        <div className={styles.buyBookLinkGroup}>
                          <a href={buyLink} className={styles.shoppingCartLink}>
                            <ShoppingCartIcon />
                          </a>
                          <a href={buyLink} className={styles.buyLink}>
                            Buy
                          </a>
                        </div>
                      </div>
                    )}
                    {(isCommunityVersion && !ilfLink) ||
                      (!isCommunityVersion && !buyLink && (
                        <div>
                          <strong>{label}</strong>
                        </div>
                      ))}
                    <div className={styles.extraText}>
                      <span>{extraText}</span>
                    </div>
                  </div>
                  {image && (
                    <a href={href} target="_blank" rel="noreferrer">
                      <img
                        src={image}
                        alt={label}
                        onError={(i) => {
                          console.log("Media error");
                          i.target.style.display = "none";
                        }}
                        style={{
                          width: "100%",
                          margin: "auto",
                          marginTop: 10,
                        }}
                      />
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <span className={styles.noDataMessage}>{noDataMessage}</span>
        )}
      </div>
    </div>
  );
};

export default BookList;
