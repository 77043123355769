import React from "react";
import styles from "./filters.module.scss";
import { connect } from "react-redux";
import { toggleFilter as _toggleFilter, selectFilters } from "../../ducks";
import { TickIcon } from "../icons";

const FilterButton = ({ active, toggleFilter, filter, marginRight }) => {
  const activeClass = active ? styles.active : styles.inactive;
  return (
    <button
      className={`${styles.filterButton} ${activeClass}`}
      style={{ marginRight: marginRight }}
      onClick={() => {
        toggleFilter(filter);
      }}
    >
      <div className={styles.filterButtonInner}>
        {active && (
          <div className={styles.tickWrapper}>
            <TickIcon />
          </div>
        )}
        <span>{filter.label}</span>
      </div>
    </button>
  );
};

const Filters = ({ filters, toggleFilter }) => {
  const fullWidthFilters = filters.length > 3;
  return (
    <div
      className={styles.filterButtonArea}
      style={{
        display: "flex",
        justifyContent: fullWidthFilters ? "space-between" : "center",
      }}
    >
      {filters.map((f) => {
        return (
          <FilterButton
            key={f.key}
            toggleFilter={toggleFilter}
            active={f.active}
            filter={f}
            marginRight={fullWidthFilters ? 0 : "1.25rem"}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: selectFilters(state),
  };
};

const mapDispatchToProps = {
  toggleFilter: _toggleFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
