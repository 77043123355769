import React from "react";
import Select from "react-select";
import styles from "./search-bar.module.scss";
import { SearchIcon } from "../icons";
import { customSearchbarStyles } from "../../style/variables";

function SearchBar({
  options,
  onChange,
  optionComponent,
  filterOption,
  placeholder,
  value,
  inputValue,
  onInputChange,
  noOptionsMessage,
  onMenuClose,
  onMenuOpen,
}) {
  return (
    <div className={styles.searchbarWrapper}>
      <div className={styles.searchIcon} style={{ display: "flex" }}>
        <SearchIcon />
      </div>
      <Select
        options={options}
        noOptionsMessage={noOptionsMessage}
        styles={customSearchbarStyles}
        isClearable={true}
        placeholder={placeholder}
        className={styles.searchBar}
        components={optionComponent}
        onChange={onChange}
        onInputChange={onInputChange}
        value={value}
        onMenuClose={onMenuClose}
        filterOption={filterOption}
        inputValue={inputValue}
        onMenuOpen={onMenuOpen}
      />
    </div>
  );
}

export default SearchBar;
